import React, { useState, useEffect } from 'react';
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import CountUp from 'react-countup';
import ScrollReveal from 'scrollreveal';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';
import '../CSS/Global.css'
import Footer from './Footer';
import Logo from '../IMG/logo-removebg-preview.png'
import wcuImg from '../IMG/main4.webp'
import gridImg from '../IMG/main5.webp'
import gridImg2 from '../IMG/main6.webp'
import gridImg3 from '../IMG/main7.webp'
import gridImg4 from '../IMG/main8.webp'
import aboutImg1 from '../IMG/main9.webp'
import aboutImg2 from '../IMG/NN2NR7V.webp'
import facility1 from '../IMG/main10.webp'
import facility2 from '../IMG/main11.webp'
import facility3 from '../IMG/main12.webp'
import HomeApp from './HomeApp';
import ScrollingText from './ScrollingText';
import FooterApp from './FooterApp';

const Home = () => {

    const [isApp, setIsApp] = useState(false);
    const [isBrowser, setIsBrowser] = useState(false);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [admission, setAddmission] = useState({});
    const [data, setData] = useState([]);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    const navigate = useNavigate()
    const [visible, setVisible] = useState(true);

    const facilities = [
        {
            id: 1,
            img: facility1,
            title: "Beginner Level 1",
            description: "This is the starting point of every skater in the Journey of Beginner to Master Level Skating. In This Phase the kid learns to balance and walk himself/herself in their new shoes with wheels.",
        },
        {
            id: 2,
            img: facility2,
            title: "Beginner Level 2",
            description: "This is the second Phase of the skating journey where Level 1 kids are promoted to learn new skills which includes postures, leg movement, Hand Movement, bending etc. In this phase Kids are confident and are willing to challenge themselves for more advanced techniques.",
        },
        {
            id: 3,
            img: facility3,
            title: "Intermediate Level 1",
            description: "In this Phase of the Journey Skater is Confident enough to get out of their beginners shoes and adapt to the more speed and high competition level shoes. In this phase of Skating a kid learns to balance and walk on their speed skates",
        },
        {
            id: 4,
            img: facility1,
            title: "Intermediate Level 2",
            description: "When a kid is confident with their Speed shoes they are promoted to this level of the program where he/she learns more advanced skills in their journey. They learn the Basics of Competitions and handle the heat practically.",
        },
        {
            id: 5,
            img: facility3,
            title: "Advanced Level 1",
            description: "Once the Kid has got the Internal Basic and training ideas according to the competitions In Level 1 of Advanced Stage The kid gets to learn and participate in local competitions to get the first hand experience of races and other advanced kids. Here the kid spends most of their time with advanced skills and has focus in winning the competitions. ",
        },
        {
            id: 6,
            img: facility2,
            title: "Advanced Level 2",
            description: "Once the kid gets use-to the competitions and races locally we identify problems (if any) and help our kid surpass those errors and work on their postures, technicality, body Language postures very closely and we make them more enhanced and we help them get ready for the states and Nationals.",
        },
        {
            id: 7,
            img: facility1,
            title: "Super Advanced",
            description: "This is the phase where students have to get out of their own comfort zones as the heat gets really hot here. In this Phase of the Journey kids are pushed and trained hard to get selected and compete in the state and National level. Here all the errors and mistakes are surpassed and kid are confident enough to “Race and Win” ",
        },
        {
            id: 8,
            img: facility3,
            title: "Master",
            description: "This is the phase where the student is at national level and he/she practices and drill more to get better at their skills and be a consistent winner in their respective Races. This is a Phase where students choose their next Goal i.e, Inline Skates.",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? facilities.length - 3 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 3 >= facilities.length ? 0 : prevIndex + 1));
    };


    useEffect(() => {
        const detectEnvironment = async () => {
            try {
                // Check if running inside a native mobile app
                const appInfo = await App.getInfo();
                const deviceInfo = await Device.getInfo();

                console.log("App Info:", appInfo);
                console.log("Device Info:", deviceInfo);

                if (deviceInfo.platform === "ios" || deviceInfo.platform === "android") {
                    if (appInfo.name) {
                        setIsApp(true);
                        setIsBrowser(false);
                        console.log("✅ Running inside a mobile app WebView (Android/iOS)");
                    } else {
                        setIsApp(false);
                        setIsBrowser(true);
                        console.log("🌍 Running in a mobile browser (Chrome/Safari)");
                    }
                } else {
                    setIsApp(false);
                    setIsBrowser(true);
                    console.log("🖥 Running in a desktop browser");
                }
            } catch (error) {
                console.error("Error detecting environment:", error);
                setIsApp(false);
                setIsBrowser(true);
            }
        };

        detectEnvironment();
    }, []);

    useEffect(() => {
        if (isApp === false) {
            // Execute animations and scroll effects only if NOT in app
            animateProgressBars();
            ScrollReveal().reveal('.main', {
                origin: 'top',
                distance: '50px',
                duration: 1200,
                opacity: 0,
                scale: 1.05,
                easing: 'ease-in-out',
            });
        }
    }, [isApp]); // Runs only when `isApp` changes

    // Function to animate progress bars
    const animateProgressBars = () => {
        document.querySelectorAll('.wcu-pb').forEach(bar => {
            const progress = bar.getAttribute('data-progress');
            bar.querySelector('span').style.width = `${progress}%`;
        });
    };

    const handleNavigate = () => {
        sessionStorage.setItem("mainhometutorila", "false")
        navigate("/login")
    }

    const fetchSliderData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/sliderhomefirst/admin1997`);
            const res = await response.json();

            const parsedData = res.map(item => ({
                ...item,
                slider_hashtag: item.slider_hashtag ? JSON.parse(item.slider_hashtag) : []
            }));
            setData(parsedData);

        } catch (error) {
            console.error("Error fetching slider data:", error);
        }
    };

    const [aboutData, setAboutData] = useState({})
    const [chooseData, setChooseData] = useState([])
    const fetchaboutData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/homeabout/single/detailall`);
            const res = await response.json();
            if (res.para1 === "Active") {
                if (res.pargraph) {
                    res.pargraph = JSON.parse(res.pargraph);
                }
                setAboutData(res);
                console.log(res); // Log parsed data
            }
        } catch (error) {
            console.error("Error fetching slider data:", error);
        }
    };


    const [colTwoData, setColTwoData] = useState([])
    const fetchaboutColTwoData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/homeaboutcoltwo/aboutdetail/getalldetail`);
            const res = await response.json();
            console.log(res);
            // res = res.filter(slider => slider.status === "Active");
            setColTwoData(res)

        } catch (error) {
            console.error("Error fetching slider data:", error);
        }
    };

    const [heading, setheading] = useState("")
    const fetchChooseUs = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/whychooseus/aboutchoose/getalldetail`)
            const res = await response.json();
            console.log(res);
            // res = res.filter(slider => slider.status === "Active");
            const activeData = res.filter(item => item.status === "Active");

            setChooseData(activeData);

        } catch (error) {
            console.log(error);
        }
    }

    const fetchChooseData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/chooseus`)
            const res = await response.json();
            console.log(res);
            if (res.data.status === "Active") {
                setheading(res.data.heading)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [bookhead, setBookhead] = useState({})

    const fetchbook1Data = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/consultinghead`)
            const res = await response.json();
            console.log(res);
            if (res.data.status === "Active") {
                setBookhead(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }



    const [bookPart1, setBookPart1] = useState([]);
    const fetchbook2Data = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/bookconsultion/getall/getallbook`);
            const res = await response.json();
            // res = res.filter(slider => slider.status === "Active");
            // Ensure that hashtag is correctly parsed
            const parsedData = res.map(item => ({
                ...item,
                hashtag: item.hashtag ? JSON.parse(item.hashtag) : [] // Convert string to array
            }));

            console.log(parsedData); // Verify the output
            setBookPart1(parsedData);
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };


    const [bookPart2, setBookPart2] = useState([])
    const fetchbook3Data = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/bookconsultionsecond/getall/getallbook`)
            const res = await response.json();
            // console.log(res);
            // const activeSliders = res.filter(slider => slider.status === "Active");
            setBookPart2(res)
        } catch (error) {
            console.log(error)
        }
    }


    const [bookPartImage, setBookPartImage] = useState({})
    const fetchbookImageData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/bookconsultionimage`)
            const res = await response.json();
            console.log(res);
            if (res.data.status === "Active") {
                setBookPartImage(res.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const [bookPartEnd, setBookPartEnd] = useState([]);

    const fetchbookEndData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/bookbelowdetail/getall/getallbook`);
            const res = await response.json();

            // Sort by position (ascending order)
            const sortedData = res.sort((a, b) => Number(a.position) - Number(b.position))
            console.log(sortedData); // Verify sorting in console
            setBookPartEnd(sortedData);
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const [programHead, setProgramHead] = useState({})
    const fetchProgram = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/programhead`);
            const res = await response.json();
            console.log(res);

            // Ensure data is not null before setting state

            setProgramHead(res.data);

        } catch (error) {
            console.log(error);
        }
    }


    const [programSlider, setProgramSlider] = useState([])
    const fetchProgramSlider = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/programslider/getall/getallprogram`);
            const res = await response.json();
            console.log(res);

            // Ensure data is not null before setting state
            const activeSliders = res.filter(slider => slider.status === "Active");

            setProgramSlider(activeSliders);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchSliderData();
        fetchaboutData();
        fetchaboutColTwoData();
        fetchChooseUs();
        fetchChooseData();
        fetchbook1Data();
        fetchbook2Data();
        fetchbook3Data();
        fetchbookImageData();
        fetchbookEndData();
        fetchProgram();
        fetchProgramSlider();
    }, [])


    return (
        <>

            <Header />
            <ScrollingText visible={visible} setVisible={setVisible} />
            <div className={`app-section ${isApp ? "" : "home-hidden"}`}>
                <HomeApp />
            </div>
            <div className={`browser-section ${isApp ? "home-hidden" : ""}`}>

                {/* Main Section */}
                {/* <div className='bg-color'>
                        <div className="main">
                            <div className="main-logo">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="main-content">
                                <h2>The Birthplace of <span>Skate Greatness</span></h2>
                                <p>Radical Sports Skate Academy is a premier roller skate academy dedicated to training.</p>

                                {
                                    login ? <div className="main-btn" >
                                        <Link to="/admission">Join Our Academy</Link>
                                    </div> :
                                        <div className="main-btn" onClick={handleNavigate} >
                                            <Link to="#">Join Our Academy</Link>
                                        </div>
                                }
                            </div>
                        </div>
                    </div> */}
                <section style={{ marginTop: !visible ? "60px" : "0px" }} className="facility-section">
                    <div className="facility-heading">
                        {/* <h2>Welcome to Radical Sports Academy <span></span></h2>
                            <p>Discover our top-notch skating facilities designed.</p> */}
                    </div>
                    <div className="facility-grid">
                        {
                            data.map((ele, idx) => {
                                // Parse and format the hashtag
                                const hashtags = ele.slider_hashtag
                                    ? (ele.slider_hashtag)
                                        .map(tag => `#${tag.toUpperCase()}`) // Capitalize and add #
                                        .join(' ') // Join with spaces
                                    : '';

                                return (
                                    <div className="facility-item" key={idx}>
                                        <img src={`https://100billionoms.top/${ele.slider_image}`} alt="Skating Rink" />
                                        <div className="facility-overlay">
                                            <h4>{ele.slider_name}</h4>
                                            <p>{ele.slider_para}</p>
                                            <p>{hashtags}</p> {/* Display formatted hashtags */}
                                            <div className='radicalslider1'>
                                                <button className='radicalbuttonslider' onClick={() => navigate(`/${ele.slider_extra}`)}>
                                                    {ele.slider_button}
                                                </button>
                                                {
                                                    (ele.slider_buttonsecond !== "" || ele.slider_buttonsecond !== null) && <button className='radicalbuttonslider1' onClick={() => navigate(`/${ele.slider_extra1}`)}>
                                                        {ele.slider_buttonsecond}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }


                        {/* 
                            
                            <div className="facility-item">
                                                <img src={facility1} alt="Skating Rink" />
                                                <div className="facility-overlay">
                                                    <h4>Welcome to Radical Sports Academy</h4>
                                                    <p>Where Champions are Made</p>
                                                    <p>#BELIEVEROFCHANGER</p>
                                                    <div className='radicalslider1'>
                                                        <button className='radicalbuttonslider' onClick={() => navigate("/about")}>About Us</button>
                                                        <button className='radicalbuttonslider1' onClick={() => navigate("/contact")}> Contact Us</button>
                                                    </div>
                                                </div>
                                            </div>
                            <div className="facility-item">
                                <img src={facility2} alt="Training Area" />
                                <div className="facility-overlay">
                                    <h4>Leading Skating Coaching in India</h4>
                                    <p>Journey from Beginner Level to CompetItion Ready</p>
                                    <div className='radicalslider1'>
                                        <button className='radicalbuttonslider' onClick={() => navigate("/admission")}> Enroll Now</button>
                                        <button className='radicalbuttonslider1' onClick={() => navigate("/enquiry")}> Free Consultancy / Trial</button>
                                    </div>
                                </div>
                            </div>
                            <div className="facility-item">
                                <img src={facility3} alt="Equipment" />
                                <div className="facility-overlay">
                                    <h4>All Time Favorites Gears and Equipments</h4>
                                    <p> Best Quality Durable and Safe Gears for your Skating Journey</p>
                                    <div className='radicalslider1'>
                                        <button className='radicalbuttonslider' onClick={() => navigate("/equipment")}>Buy Now</button>
                                        <button className='radicalbuttonslider1' onClick={() => navigate("/equipment")}>Visit Store</button>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </section>

                <div className="wcu-section">




                    <div className="wcu-col">
                        <div className="wcu-slogan">{aboutData.heading || "About the Academy"}</div>
                        <div className="wcu-sub">
                            {aboutData.subheading || "Radical Sports Academy –"}
                            {/* <span>Best Skating Classes in Mumbai</span> */}
                        </div>
                        <div className="wcu-para">
                            {aboutData.pargraph && aboutData.pargraph.length > 0 ? (
                                aboutData.pargraph.map((para, idx) => (
                                    <React.Fragment key={idx}>
                                        {idx > 0 && <> <br /> <br /></>} {/* Add <br> after the first paragraph */}
                                        {para}
                                    </React.Fragment>
                                ))
                            ) : (
                                null
                            )}
                        </div>
                    </div>




                    <div className="wcu-col col2">
                        <div className="wcu-img">
                            <img src={`https://100billionoms.top/${colTwoData.image}`} alt="Skate Image" className="wcu-img" />
                        </div>
                        <div className="detail">
                            <h4><CountUp end={colTwoData.age} duration={2} /></h4>
                            <p>{colTwoData.paraform}</p>
                        </div>
                    </div>





                </div>


                {/* <div className="wcu-section">
                        
                        <div className="wcu-col">
                            <div className="wcu-sub">Why Choose Us</div>
                            <div className="wcu-slogan">Skate with <span>Style</span></div>
                            <div className="wcu-para">
                                Experience the thrill of skating like never before with our top-of-the-line equipment and accessories.
                            </div>
                            <div className="wcu-pb-container">
                                <div className="wcu-pb" data-progress="40"><span></span></div>
                                <div className="wcu-pb-label">Quality</div>
                            </div>
                            <div className="wcu-pb-container">
                                <div className="wcu-pb" data-progress="70"><span></span></div>
                                <div className="wcu-pb-label">Speed</div>
                            </div>
                            <div className="wcu-pb-container">
                                <div className="wcu-pb" data-progress="90"><span></span></div>
                                <div className="wcu-pb-label">Durability</div>
                            </div>
                        </div>

                       
                        <div className="wcu-col col2">
                            <div className="wcu-img">
                                <img src={wcuImg} alt="Skate Image" className="wcu-img" />
                            </div>
                            <div className="detail">
                                <h4><CountUp end={8} duration={2} /></h4>
                                <p>Years of Rich History</p>
                            </div>
                        </div>

                        
                        <div className="wcu-col">
                            <div className="wcu-counter" data-target="150">
                                <div className="wcu-group">
                                    <i className="fas fa-thumbs-up"></i>
                                    <div className="num"><CountUp end={10} duration={2} />+</div>
                                </div>
                                <div className="txt">Centers</div>
                            </div>
                            <div className="wcu-counter" data-target="25">
                                <div className="wcu-group">
                                    <i className="fas fa-trophy"></i>
                                    <div className="num"><CountUp end={1000} duration={2} />+</div>
                                </div>
                                <div className="txt">Awards</div>
                            </div>
                            <div className="wcu-counter" data-target="500">
                                <div className="wcu-group">
                                    <i className="fa-solid fa-person-skating"></i>
                                    <div className="num"><CountUp end={500} duration={2} />+</div>
                                </div>
                                <div className="txt">Skaters</div>
                            </div>
                        </div>
                    </div> */}

                {/* Programs Section */}
                <div>
                    <h1 className='programgrid-heading'>{heading}</h1>
                    <div className="program-grid" id="program">
                        {
                            chooseData.map((ele, idx) => {
                                return <>
                                    <div className={idx === 1 ? "program-grid2" : "program-grid1"}>
                                        <Link to="/enquiry">
                                            <div className="program-grid-img">
                                                <img src={`https://100billionoms.top/${ele.image}`} alt="Image 1" />
                                            </div>
                                            <div className="program-overlay">
                                                <h3>{ele.age}</h3>
                                                <p>{ele.paraform}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            })
                        }




                    </div>
                </div>
                {/* <div>
                        <h1 className='programgrid-heading'>Why Choose Us</h1>
                        <div className="program-grid" id="program">
                            <div className="program-grid1">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg} alt="Image 1" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3> Expert Coaching Faculty</h3>
                                        <p>Our certified and experienced coaches bring years of expertise in figure skating, speed skating,
                                            and roller skating. They focus on personalized training to ensure each skater reaches their full
                                            potential.</p>
                                    </div>
                                </Link>
                            </div>

                            <div className="program-grid2">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg2} alt="Image 2" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3>State-of-the-Art Facilities</h3>
                                        <p>Get trained in a world class environment with best skating Coaches, top-notch safety gear, and
                                            the latest training equipment. Our batches are designed to support both age groups starting with
                                            3 years old and above i.e. from beginners to advanced athletes.</p>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="program-grid5">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg4} alt="Image 4" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3>Structured Training Programs</h3>
                                        <p>We offer tailored programs for all age groups starting with 3 years old  and skill levels, from
                                            beginner lessons to advanced competitive training. Our curriculum follows international
                                            standards to prepare skaters for competitions and certifications.</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="program-grid5">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg4} alt="Image 4" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3> Focus on Safety & Skill Development</h3>
                                        <p>Skating should be fun and safe! We prioritize injury prevention with proper guidance, warm-ups,
                                            and safety measures, ensuring a secure learning experience for all students</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="program-grid5">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg4} alt="Image 4" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3>Competitive Edge & Performance Opportunities </h3>
                                        <p>For aspiring professionals, we provide opportunities to participate in local,state, national, and
                                            international skating competitions. Our training methods focus on technical skills, agility, and
                                            mental strength to build confident athletes. </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="program-grid5">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg4} alt="Image 4" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3>Supportive & Inclusive Community</h3>
                                        <p>We believe in fostering a positive and encouraging environment. Whether you're here for
                                            fitness, recreation, or professional training, you'll be part of a community that motivates and
                                            supports you every step of the way. </p>
                                    </div>
                                </Link>
                            </div>
                            <div className="program-grid5">
                                <Link to="/enquiry">
                                    <div className="program-grid-img">
                                        <img src={gridImg4} alt="Image 4" />
                                    </div>
                                    <div className="program-overlay">
                                        <h3> Flexible Class Schedules & Affordable Pricing</h3>
                                        <p>With a variety of batch timings and membership options, we make it easy for you to find a
                                            program that fits your schedule and budget.</p>
                                    </div>
                                </Link>
                            </div>
                           
                        </div>
                    </div> */}

                {/* About Section */}
                <section className="about-us" id="about">
                    <div className="about-container">
                        <div className="about-row">
                            {/* First Column */}
                            <div className="about-col-md-6">
                                <div className="about-text">
                                    <h2>{bookhead.heading}</h2>
                                    <h3>{bookhead.para}<span></span></h3>
                                    {
                                        bookPart1.map((ele, idx) => {
                                            return <blockquote className="about-quote">
                                                <p>{ele.name}</p>
                                                <footer>{ele.para}: </footer>
                                                {
                                                    ele.hashtag.map((ele, idx) => {
                                                        return <footer> &nbsp; &nbsp; &nbsp; - {ele}  </footer>
                                                    })
                                                }

                                            </blockquote>
                                        })
                                    }

                                    {
                                        bookPart2.map((ele, idx) => {
                                            return (
                                                <blockquote className="about-quote">
                                                    <p>{ele.heading}</p>
                                                    <footer style={{ fontWeight: "bold" }}>{ele.para}</footer>
                                                    <footer> <button className='booknow-home' onClick={() => navigate("/admission")}>{ele.para1}</button> </footer>
                                                </blockquote>
                                            )
                                        })
                                    }

                                    <div className="about-counter">
                                        {
                                            bookPartEnd.map((ele, idx) => {
                                                return <div className="about-counter-item">
                                                    <span className="about-count"><CountUp end={ele.book_total} duration={2} />+</span>
                                                    <p>{ele.book_name}</p>
                                                </div>
                                            })
                                        }

                                        {/* <div className="about-counter-item">
                                                <span className="about-count"><CountUp end={4000} duration={2} />+</span>
                                                <p>Trained Skaters</p>
                                            </div>

                                            <div className="about-counter-item">
                                                <span className="about-count"><CountUp end={1000} duration={2} />+</span>
                                                <p>Championship Titles</p>
                                            </div>
                                            <div className="about-counter-item">
                                                <span className="about-count"><CountUp end={10} duration={2} /></span>
                                                <p>World-Class Training Centers</p>
                                            </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* Second Column */}
                            <div className="about-col-md-6">
                                <div className="about-image">
                                    <img src={`https://100billionoms.top/${bookPartImage.image2}`} alt="Academy Image" className="about-img1" />
                                    <img src={`https://100billionoms.top/${bookPartImage.image1}`} alt="Secondary Image" className="about-img2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Facility Section */}
                {/* <section className="facility-section">
                        <div className="facility-heading">
                            <h2>Our Skating <span>Facilities</span></h2>
                            <p>Discover our top-notch skating facilities designed.</p>
                        </div>
                        <div className="facility-grid">
                            <div className="facility-item">
                                <img src={facility1} alt="Skating Rink" />
                                <div className="facility-overlay">
                                    <h4>Premier Skating Rink</h4>
                                    <p>Enjoy skating on our state-of-the-art rink, perfect for both practice and performances.</p>
                                </div>
                            </div>
                            <div className="facility-item">
                                <img src={facility2} alt="Training Area" />
                                <div className="facility-overlay">
                                    <h4>Training Area</h4>
                                    <p>Access to specialized training zones tailored to enhance your skills.</p>
                                </div>
                            </div>
                            <div className="facility-item">
                                <img src={facility3} alt="Equipment" />
                                <div className="facility-overlay">
                                    <h4>High-Quality Equipment</h4>
                                    <p>We provide the best equipment to ensure a top-notch training experience.</p>
                                </div>
                            </div>
                        </div>
                    </section> */}

                <section className="facility-section">
                    <div className="facility-heading">
                        <h2>{programHead.heading}<span></span></h2>
                        <p>{programHead.para} </p>
                    </div>

                    <div className="slider-container">
                        <button className="slider-button left" onClick={handlePrev}>&#10094;</button>

                        <div className="facility-slider">
                            {programSlider.slice(currentIndex, currentIndex + 3).map((facility) => (
                                <div key={facility.id} className="facility-item">
                                    <img src={`https://100billionoms.top/${facility.slider_image}`} alt={facility.title} />
                                    <div className="facility-overlay">
                                        <h4>{facility.slider_name}</h4>
                                        <p>{facility.slider_para}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button className="slider-button right" onClick={handleNext}>&#10095;</button>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
}

export default Home;
