import img1 from "../IMG/gridnull.jpg"
import Footer from "./Footer";
import Header from "./Header";

const Centers = () => {
    const offices = [
        {
            id: 1,
            name: "Malad",
            address: "Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
            type: "Corporate office",
            image: img1,
        },
        {
            id: 2,
            name: "Goregaon",
            address: "Rosary Church Campus, M.G. Road Cardinal Gracias Nagar, Goregaon West, Mumbai, Maharashtra 400104",
            type: "Center office",
            image: img1,
        },
        {
            id: 3,
            name: "Malad",
            address: "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
            type: "Center office",
            image: img1,
        },
        {
            id: 4,
            name: "Malad",
            address: "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
            type: "Center office",
            image: img1,
        },
        {
            id: 5,
            name: "Mumbai",
            address: "5th floor, Andheri East, Mumbai - 400059.",
            type: "Center office",
            image: img1,
        },
        {
            id: 6,
            name: "Mumbai",
            address: "Ground floor, Hitech City, Mumbai - 400081.",
            type: "Center office",
            image: img1,
        },
    ];

    return (
        <>
            <Header />
            <div>
                <div className="offices-section">
                    <h2>Our Centers</h2>
                    <div className="offices-container">
                        {offices.map((office) => (
                            <div key={office.id} className="office-card">
                                <div className="office-image">
                                    <img src={office.image} alt={office.name} />
                                    <span className="office-type">{office.type}</span>
                                </div>
                                <div className="office-info">
                                    <h3>{office.name}</h3>
                                    <p>
                                        <i className="fas fa-map-marker-alt"></i> {office.address}
                                    </p>
                                    <a href={`https://www.google.com/maps?q=${office.address}`} target="_blank" rel="noopener noreferrer" className="directions-btn">
                                        <i className="fas fa-external-link-alt"></i> View Directions
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Centers;